import Navbar from "../components/general/header/Navbar";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/general/footer/Footer";
import { ScrollToTop } from "../components/general/ScrollToTop";
import axiosInstance from "../axios/axiosInstance";
import useLogout from "../hooks/useLogOut";
import { useScrollToTop } from "../hooks/useScrollToTop";
import useTokenExpiration from "../hooks/useTokenExpiration";
import usePathHistory from "../hooks/usePathHistory";
export const Root = () => {
  const logout = useLogout();
  // create an axios interceptor to check if user is authenticated or not
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        logout("The session expired, try login again", false);
      }
      return Promise.reject(error);
    }
  );
  // use custom hooks 
  useScrollToTop();  // scroll to top when route changes
  useTokenExpiration(); // check exp date of token and remove it if it is expired
  usePathHistory(); // use save path history hook

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </>
  );
};
